@import '../../../styles/customMediaQueries.css';

.root {
}

.input {
  margin-bottom: 24px;
}

.checkbox {
  margin-bottom: 24px;
  
  & label > span > svg {
    transform: scale(1.5);
  }

  & label svg path[class^="FieldCheckbox_box"] {
    stroke: var(--marketplaceColor);
  }
}

.locationAutocompleteInput {
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.deliveryOption {
  margin-left: 30px;
  @media (--viewportMedium) {
    margin-left: 60px;
  }
}

.deliveryCheckbox {
  margin-bottom: 24px;
}

.disabled {
  color: var(--colorGrey300);
}

.hidden {
  display: none;
}

.errorText {
  font-size: 14px;
  color: var(--colorFail);
  margin-bottom: 24px;
}

.submitButton {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 72px;
  }
}
